import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';

const title = 'dashboard.title';

@Component({
  name: 'Dashboard',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
  },
})
export default class Dashboard extends Vue {
  title = title;
}
